.requests-filter-container {
  position: relative;
  border-bottom: 1px solid #a9a9bc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.requests-filter-option {
  position: relative;
  width: 180px;
  padding: 12px 0;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  /* font-size: 16px; */
  line-height: 20px;
  color: #a9a9bc;
}
/* 
  .filter-option--withdrawal {
    margin-left: 50px;
  } */

.active-filter-option {
  color: #1a0404 !important;
}

.active-filter-option::after {
  content: "";
  position: absolute;
  width: 180px;
  height: 4px;
  left: 0;
  bottom: 0;
  /* Gradient */
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
}

/* salary-advance-request-table-section && bnpl-requests-table-section && on-demand-requests-table-section*/
.salary-advance-requests-table-section,
.bnpl-requests-table-section,
.on-demand-requests-table-section {
  margin-top: 30px;
}

.approve-withdrawal-request-loading-spinner-wrapper {
  position: absolute;
  right: 20px;
  top: 140px;
}

.request-awaiting-approval-wrapper {
  color: #b5179e !important;
}

.request-rejected-wrapper {
  color: #ecbe1c !important;
}

.request-completed-wrapper {
  color: #00ba07 !important;
}

.request-awaiting-otp-wrapper {
  color: #3a0ca3;
}

.td-request-rejection-reason--wrapper {
  color: #ff4848;
}

.show-balance-button-wrapper button {
  width: 120px;
  height: 32px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  background-color: rgba(58, 12, 163, 0.9);
  color: #ffffff;
}

.show-balance-button-wrapper button:hover {
  background-color: rgba(58, 12, 163, 1);
}

/* Media queries */
@media (min-width: 1400px) {
  .withdrawals-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .withdrawals-container--inner {
    max-width: 1700px;
  }
}
/* 
@media (min-width: 1450px) {
  .withdrawals-container--inner {
    max-width: 1320px;
  }
} */
