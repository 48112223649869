.search-voucher-modal-container {
  position: absolute;
  max-width: 380px;
  width: 100%;
  min-height: 250px;
  z-index: 50;
  /* transform: translate(50%); */
  left: 40%;
  top: 25%;
  background: #ffffff;
  border-radius: 20px;
}

.search-voucher-modal-container--inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
}

.search-voucher-header-text {
  font-family: "moderat-semibold";
  font-size: 20px;
  line-height: 20px;
  color: #1a0404;
  text-align: center;
}

.search-voucher-modal-container--inner form {
  margin-top: 25px;
  width: 100%;
}

.search-voucher-modal-container--inner .form-group {
  width: 100%;
}

.search-voucher-modal-container--inner .form-group input {
  width: 100%;
}

.search-for-voucher-button-container {
  margin-top: 45px;
  justify-content: flex-end;
}

.search-for-voucher-button-wrapper button {
  width: 200px;
}
