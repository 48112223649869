.employers--top-container {
  display: flex;
  align-items: center;
  column-gap: 44px;
}

/* employers--top--box-container */
.employers--top--box-container {
  width: 260px;
  height: 160px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 0 20px 30px;
  color: #0d0520;
}

.box-title-wrapper {
  font-family: "moderat-semibold";
  font-size: 13px;
  line-height: 12px;
  display: flex;
  align-items: center;
}

.box-value-wrapper {
  margin-top: 38px;
  font-family: "moderat-semibold";
  font-size: 32px;
  line-height: 38px;
  word-wrap: break-word;
}

/* Section.employers-table-section */
section.employers-table-section {
  margin-top: 60px;
}

/* employers-table--top-wrapper */
.employers-table--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* requests--table-filter-option-wrapper */
.requests--table-filter-option-wrapper {
}

/* add-new-button-wrapper */
.add-new-button-wrapper {
  margin-right: 46px;
}

.add-new-button-wrapper button {
  width: 250px;
  height: 50px;
  background: #3a0ca3;
  border-radius: 10px;
  border: none;
  font-family: "moderat-medium";
  color: #ffffff;
  font-size: 15px;
  line-height: 15px;
}

/*  */
.employers--actions-handler-wrapper {
  top: 80px !important;
  right: 30px !important;
}

/* Media queries */
@media (min-width: 1400px) {
  .employers-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .employers-container--inner {
    max-width: 1700px;
  }
}
