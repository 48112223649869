.merchant-preview-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.merchant-preview-top--left-wrapper {
  display: flex;
  align-items: center;
}

.merchant-preview-top--right-wrapper {
  display: flex;
  align-items: center;
  column-gap: 50px;
}

.hold-account-button-wrapper {
  display: flex;
  align-items: center;
}

.hold-account-button-wrapper button,
.delete-user-button-wrapper button {
  width: 220px;
  height: 50px;
  border-radius: 10px;
  border: none;
  font-family: "moderat-medium";
  color: #ffffff;
  font-size: 15px;
  line-height: 15px;
}

.hold-account-button-wrapper button {
  background: #3a0ca3;
  margin-left: 10px;
}

.delete-user-button-wrapper button {
  background: #ce2e2e;
}

.merchant-preview-top-container .back-button-wrapper button {
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

/* Stock Preview Row One Section / Merchant Info Section */
.merchant-preview--row-one-section {
  margin-top: 30px;
  margin-right: 20px;
}

/* Stock Preview Row Two Section / Merchant Info Section */
.merchant-preview--row-two-section {
  margin-top: 60px;
  margin-right: 20px;
}

/* merchant-stock-boxes-container */
.merchant-stock-boxes-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 70px;
  row-gap: 40px;
  margin-top: 20px;
}

.merchant-stock-box-wrapper {
  width: 337px;
  height: 275px;
  background: #ffffff;
  border-radius: 10px;
  padding: 35px 15px 20px 25px;
}

/* Top Wrapper */
.merchant-stock-box--top-wrapper {
  display: flex;
  align-items: center;
  column-gap: 60px;
}

.merchant-stock-image-wrapper {
  width: 99px;
  height: 99px;
  flex-shrink: 0;
  background: #d9d9d9;
  border-radius: 20px;
  overflow: hidden;
}

.merchant-stock-image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Top Right Wrapper */
.merchant-stock-box--top--right-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 33px;
  justify-content: space-between;
}

.merchant-stock-box--bottom-wrapper {
  margin-top: 19px;
}

.merchant-stock-box--bottom-wrapper--title-wrapper,
.other-stock-text-wrapper {
  font-family: "moderat-medium";
  font-size: 13px;
  line-height: 12px;
  color: #a9a9bc;
}

/* .merchant-stock-variants-images-wrapper */
.merchant-stock-variants-images-wrapper {
  display: flex;
  align-items: center;
  column-gap: 14px;
}

.stock-variants-image-box-wrapper {
  width: 65px;
  height: 65px;
  flex-shrink: 0;
  border-radius: 20px;
  margin-top: 10px;
  overflow: hidden;
}

.stock-variants-image-box-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
