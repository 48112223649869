.navbar-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  background-color: white;
  padding: 0 16px;
  font-family: moderat-regular;
}

.navbar-container--inner {
  max-width: 1165px;
  height: 80px;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company-logo-wrapper {
  cursor: pointer;
}

/* Nav Link Section / Midle Section*/
.top-nav-link-section {
  display: flex;
}

.nav-link-wrapper {
  margin: 0 20px;
  padding: 4px;
}

.nav-link-wrapper a {
  text-decoration: none;
  color: #000000;
  font-family: moderat-medium;
  line-height: 28px;
  font-size: 16px;
}

/* Action Button Section / Right Section */
.navbar-action-button-section {
  position: relative;
  display: flex;
  align-items: center;
}

.get-started-btn-wrapper button {
  width: 200px;
  height: 55px;
  padding: 0 16px;
  display: flex;
  justify-content: space-around;
  border: none;
  outline: none;
}

.gradientButtton {
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
  box-shadow: 0px 24px 63px rgba(169, 22, 159, 0.3);
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  display: flex;
  align-items: center;
  font-family: moderat-medium;
}

/* Get Started Modal */
.nav-get-started-modal {
  position: absolute;
  top: 56px;
  right: 0;
  z-index: 50;
}

.get-started-modal-wrapper {
  width: 350px;
  min-height: 150px;
  padding: 24px 32px;
  background-color: white;
  border-radius: 10px;
}

/* Merchant Link signup wrapper and  Employer Link signup wrapper */
.merchant-signup-link-wrapper,
.employer-signup-link-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
}

.employer-signup-link-wrapper {
  margin-top: 16px;
}

.merchant-signup-link-wrapper .icon-section,
.employer-signup-link-wrapper .icon-section {
  width: 64px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(235, 231, 246);
  border-radius: 6px;
}

.merchant-signup-link-wrapper .wrapper-main-section,
.employer-signup-link-wrapper .wrapper-main-section {
  margin-left: 20px;
}

.merchant-signup-link-wrapper .wrapper-main-section .wrapper-title-text,
.employer-signup-link-wrapper .wrapper-main-section .wrapper-title-text {
  color: #3a0ca3;
  font-weight: 700;
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
}

.merchant-signup-link-wrapper .wrapper-main-section .wrapper-sub-text,
.employer-signup-link-wrapper .wrapper-main-section .wrapper-sub-text {
  color: rgb(150, 150, 150);
  font-weight: 500;
  font-family: moderat-medium;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  margin-top: 12px;
}

/* Download App Buttons Wrapper */
.download-btn-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
}

/* MOBILE VIEW FOR THE NAVBAR LEFT */
.MOBILE-navbar-container--inner {
  display: none;
  height: 80px;
  background-color: white;
}

/* Dropdown Navbar Container */
.mobile-navbar-modal-container {
  width: 100%;
  min-height: 100vh;
  background-color: white;
  z-index: 50;
  padding: 20px 0;
  position: absolute;
  top: 0;
  left: 0;
}

/* Navclose Container */
.nav-close-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}

.mobile--nav-link-wrapper {
  border-bottom: 1px solid rgb(243, 244, 246);
  padding: 12px 0 8px;
}

/* MOBILE Merchant Link signup wrapper and  Employer Link signup wrapper */
.mobile--merchant-signup-link-wrapper,
.mobile--employer-signup-link-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(247, 164, 172);
  padding: 24px 0;
  padding-left: 20px;
}

.mobile--merchant-signup-link-wrapper .icon-section,
.mobile--employer-signup-link-wrapper .icon-section {
  width: 64px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(235, 231, 246);
  border-radius: 6px;
}

.mobile--merchant-signup-link-wrapper .wrapper-main-section,
.mobile--employer-signup-link-wrapper .wrapper-main-section {
  margin-left: 20px;
}

.mobile--merchant-signup-link-wrapper .wrapper-main-section .wrapper-title-text,
.mobile--employer-signup-link-wrapper
  .wrapper-main-section
  .wrapper-title-text {
  color: #3a0ca3;
  font-weight: 700;
  font-size: 1.25rem /* 20px */;
  line-height: 1.75rem /* 28px */;
}

.mobile--merchant-signup-link-wrapper .wrapper-main-section .wrapper-sub-text,
.mobile--employer-signup-link-wrapper .wrapper-main-section .wrapper-sub-text {
  color: rgb(150, 150, 150);
  font-weight: 500;
  font-family: moderat-medium;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  margin-top: 12px;
}

.none {
  display: none;
}
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */

/* MEDIA QUERIES */
/* lg */
@media (max-width: 1023px) {
  .nav-link-wrapper {
    margin: 0 12px;
  }
}

/* 3md */
@media (max-width: 970px) {
  .navbar-container {
    padding: 0 32px;
  }
}

/* 2md */
@media (max-width: 850px) {
  .nav-link-wrapper {
    margin: 0;
    padding-left: 20px;
    font-size: 14px;
    line-height: 20px;
  }

  .navbar-container--inner {
    display: none;
  }

  .MOBILE-navbar-container--inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .merchant-signup-link-wrapper .wrapper-main-section .wrapper-title-text,
  .employer-signup-link-wrapper .wrapper-main-section .wrapper-title-text {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
  }

  .mobile--merchant-signup-link-wrapper .wrapper-main-section .wrapper-sub-text,
  .mobile--employer-signup-link-wrapper
    .wrapper-main-section
    .wrapper-sub-text {
    margin-top: 4px;
  }
}

/* md */
@media (max-width: 767px) {
  .mobile--merchant-signup-link-wrapper,
  .mobile--employer-signup-link-wrapper {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media (max-width: 525px) {
  .navbar-container {
    padding: 0 18px;
  }

  .nav-close-container {
    padding-right: 20px;
  }
}
