.participants-action-buttons {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin: 16px 0;
}

button.participant-filter-button {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: rgba(58, 12, 163, 0.1);
  border-radius: 20px;
  border: none;
  /* text styles */
  font-family: "moderat-medium";
  font-size: 14px;
  line-height: 140%;
  color: #3a0ca3;
}

button.active--participant-filter-button {
  background: #3a0ca3;
  color: #f5f5f5;
}
