.modal-container {
  position: absolute;
  width: 340px;
  min-height: 319px;
  z-index: 50;
  left: 40%;
  top: 25%;
  background: #ffffff;
  border-radius: 20px;
}

.modal-container--inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 30px;
}

.modal-header-text {
  font-family: "moderat-semibold";
  font-size: 20px;
  line-height: 20px;
  color: #1a0404;
  margin-top: 20px;
}

.modal-sub-text {
  width: 300px;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 20px;
}

.modal-sub-text span {
  color: #3a0ca3;
}

.modal-container form {
  width: 100%;
}

.modal-action-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.confirm-modal-action-button {
  display: flex;
  align-items: center;
}

.cancel-modal-button-wrapper button,
.confirm-modal-action-button button {
  border: none;
  outline: none;
  cursor: pointer;
}

.cancel-modal-button-wrapper button {
  background-color: inherit;
  font-family: "moderat-medium";
  font-size: 15px;
  line-height: 15px;
  color: #ff4848;
}

.confirm-modal-action-button button {
  max-width: 169px;
  min-width: 150px;
  width: 100%;
  height: 50px;
  background: #3a0ca3;
  border-radius: 10px;
  font-family: "moderat-medium";
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
  transition: all ease-in-out 0.2s;
}

.confirm-modal-action-button button:hover {
  background: #320a90;
}

.confirm-modal-button-loader-wrapper {
  margin-left: 8px;
}

/* Other Modal Classes */
.otp-modal-container {
  width: 380px;
}

.otp-modal-header-text {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
  width: 100%;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
  color: #3a0ca3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otp-modal-sub-text {
  width: 100%;
  text-align: left;
}

.otp-input-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  margin-top: 30px;
}

.otp-input-wrapper input::-webkit-inner-spin-button,
.otp-input-wrapper input::-webkit-outer-spin-button {
  appearance: none;
}

.otp-input-form-group input {
  width: 45px;
  height: 50px !important;
  background: #f4f4f4;
  border: 1px solid #c0bace;
  border-radius: 10px;
  padding: 0;
  padding: 0 10px !important;
  text-align: center;
}

/* .declination-reason-form-group */
.declination-reason-form-group {
  margin-top: 15px;
  width: 100%;
}

.declination-reason-form-group input {
  width: 100%;
}

.modal--resend-otp-button-wrapper {
  position: absolute;
  left: 25px;
  top: 10px;
  display: flex;
  align-items: center;
}

.modal--resend-otp-button-wrapper button {
  display: block;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-size: 16px;
  color: #320a90;
}

.modal--resend-otp-button-wrapper button:hover {
  color: #400cb9;
}

.resend-otp-error-wrapper,
.resend-otp-success-wrapper {
  display: flex;
  align-items: center;
}

.resend-otp-error-wrapper {
  color: #ff4848;
}

.resend-otp-success-wrapper {
  color: #00ba07;
}

.upload-loading-spinner-wrapper {
  width: 15px;
  height: 15px;
  border: 4px solid #cccccc; /* Light grey */
  border-top: 4px solid #3a0ca3; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  z-index: 40;
  margin: 0 4px;
  margin-top: 2px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
