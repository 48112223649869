.course-details--form-wrapper--grid {
  /* margin-top: 16px; */
  max-width: 900px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 36px;
}

.course-details--form-wrapper--grid .form-group input,
.course-details--form-wrapper--grid .form-group select {
  width: 400px !important;
}
