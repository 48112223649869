.primary-button-container {
  display: flex;
  column-gap: 15px;
  align-items: center;
  margin-top: 60px;
}

.primary-button-wrapper {
}

.primary-button-wrapper button {
  height: 50px;
  background: #3a0ca3;
  font-family: "moderat-medium";
  font-size: 15px;
  line-height: 15px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  color: #ffffff;
  transition: all ease-in-out 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 14px;
}
