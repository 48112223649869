/* Header Section / Row One Section  */
section.products--row-one-section {
  display: flex;
  align-items: center;
  column-gap: 30px;
  padding: 0 24px;
}

.products--header-box-container {
  width: 260px;
  height: 160px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 25px;
}

/* Box Title Wrapper */
.box--title-wrapper,
.box--value-wrapper {
  font-family: "moderat-semibold";
  color: #0d0520;
}
.box--title-wrapper {
  font-size: 14px;
  line-height: 14px;
}

/* Box Value Wrapper */
.box--value-wrapper {
  font-size: 32px;
  line-height: 38px;
  margin-top: 38px;
}

/* Table Container Section / Row Two Section */
section.products--row-two-section {
  margin-top: 50px;
}

.products---row-two-top-wrapper {
  margin-top: 30px;
}

.products-filter-container {
  position: relative;
  border-bottom: 1px solid #a9a9bc;
  display: flex;
  align-items: center;
  padding-left: 90px;
  column-gap: 5px;
}

.products-filter-option {
  position: relative;
  width: 180px;
  padding: 12px 0;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  line-height: 20px;
  color: #a9a9bc;
}

/* Product-categories-table-section */
.products-table-section {
  position: relative;
  padding: 30px 24px 0 34px;
  /* margin-top: 30px; */
}

.table-action-loading-spinner-wrapper {
  position: absolute;
  right: 80px;
  top: 25px;
}

/* Media queries */
@media (min-width: 1400px) {
  .products-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .products-container--inner {
    max-width: 1700px;
  }
}
