.text-editor-container,
.text-editor-with-toolbar-container {
  border-radius: 8px;
  border: 1px solid #707070;
  overflow: hidden;
  margin-top: 0px;
}

.text-editor-with-toolbar-container
  .ql-toolbar.ql-snow
  + .ql-container.ql-snow {
  min-height: 40vh !important;
  height: 50vh !important;
  overflow: auto !important;
}

.text-editor-container .ql-editor,
.text-editor-with-toolbar-container .ql-editor {
  background-color: white;
  min-height: auto;
  height: 100%;
  padding: 20px 30px;
}

.ql-editor.ql-blank::before {
  left: 30px !important;
}

.ql-toolbar.ql-snow {
  background: #e8e8e8;
}

/* Tool bar */
.quill .ql-toolbar {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

/* Content Container */
.quill .ql-container {
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}

/* Media Queries */
@media (max-width: 495px) {
  .text-editor-container .ql-editor {
    padding: 20px 20px;
  }

  .ql-editor.ql-blank::before {
    left: 20px !important;
  }
}
