.user-registration-successful-container {
  background-color: #fff;
}

.user-registration-successful-container--inner {
  /* background-color: red; */
  max-width: 447px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 140px 0 0px;
}

.user-registration-successful--main-text {
  font-family: "moderat-semibold";
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 40px;
}

.user-registration-successful--sub-text {
  font-family: "moderat-medium";
  line-height: 27px;
  text-align: center;
  color: #4d4d4d;
  margin-top: 15px;
}

.user-registration-successful--go-back-button button {
  width: 250px;
  height: 50px;
  background: #3a0ca3;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  line-height: 25px;
  margin-left: 10px;
  margin-top: 25px;
}
