.product-deal-descrp-textarea-input {
  height: 120px !important;
}

/* File Form Group */
.product-deals-image-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.product-deals-image-form-group .file-form-group--inner {
  position: relative;
  width: 250px;
  height: 100px;
  border: 1px dashed #c0bace;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* File Input Wrapper */
.product-deals-image-form-group .file-input-wrapper {
  min-width: 160px;
  max-width: 400px;
  min-height: 33px;
  position: relative;
  background-color: #ede9f7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* File Input */
.product-deals-image-form-group
  .file-form-group--inner
  .file-input-wrapper
  input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  outline: none;
  cursor: pointer;
}

/* File Input Title */
.product-deals-image-form-group
  .file-form-group--inner
  .file-input-wrapper
  .file-input-title {
  height: 100%;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #2e2e2e;
  padding: 0 3px;
}

.product-deals-image-form-group__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  row-gap: 7px;
}

/* edit-variant-img-input */
.product-deals-image-form-group .edit-product-img-input {
  width: 140px;
  height: 33px;
  position: relative;
  background-color: #ede9f7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.edit-product-img-input input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  outline: none;
}

.product-img-preview {
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}

.image-dimension-spec-wrapper {
  font-size: 13px;
  text-align: center;
}
