section.voucher-filter-section {
}

.voucher-filter-options-wrapper .voucher-filter-form-group-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 15px;
}

.voucher-filter-options-wrapper .form-group select {
  width: 170px;
  border: none;
  color: #dadada;
  background-color: transparent;
  position: relative;
  z-index: 4;
}

.voucher-filter-options-wrapper .form-group {
  margin-left: 0;
}

.voucher-filter-options-wrapper .form-group .dropdown-icon img {
  right: 8px;
}

/* Search Bar Container */
.voucher-filter-search-bar-container {
  position: relative;
}

.voucher-filter-search-bar-container input {
  width: 320px;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  outline: none;
  padding-left: 40px;
  padding-right: 10px;
  /* Text Styles */
  font-family: "moderat-regular";
  font-size: 15px;
  line-height: 16px;
  color: #8e8e8e;
}

.voucher-filter-search-bar-container input::placeholder {
  font-family: "moderat-regular";
  font-size: 15px;
  line-height: 16px;
  color: #8e8e8e;
}

.search-icon-wrapper {
  position: absolute;
  top: 15px;
  left: 12px;
}

.search-icon-wrapper img {
  height: 22px;
}

/* Submit Button */
.voucher-filter--submit-btn-wrapper {
  margin-top: 0 !important;
  margin-left: 20px;
}

.voucher-filter--submit-btn-wrapper button {
  width: 150px;
}
