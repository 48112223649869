body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

button:focus {
  outline: none;
}

@font-face {
  /* 300 */
  font-family: moderat-light;
  font-weight: 300;
  src: url(./styles/fonts/Moderat-Thin.ttf);
}

/* 400 */
@font-face {
  font-weight: 400;
  font-family: moderat-regular;
  src: url(./styles/fonts/Moderat-Regular.ttf);
}

@font-face {
  /* 500 */
  font-weight: 500;
  font-family: moderat-medium;
  src: url(./styles/fonts/Moderat-Medium.ttf);
}

@font-face {
  /* 600 */
  font-weight: 600;
  font-family: moderat-semibold;
  src: url(./styles/fonts/Moderat-Bold.ttf);
}

@font-face {
  /* 700 */
  font-weight: 700;
  font-family: moderat-bold;
  src: url(./styles/fonts/Moderat-Black.ttf);
}

html {
  background-color: #f8f8fa;
}

/* Moderat-- font override */
.app {
  position: relative;
  font-family: "moderat-regular";
  background-color: #f8f8fa;
}

input {
  accent-color: #3a0ca3;
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  resize: none;
  font-family: "moderat-regular";
}

input::placeholder {
  font-size: 15px;
  line-height: 25px;
  font-family: "moderat-regular";
  font-weight: 400;
  color: #222222;
}

input:focus,
textarea:focus,
select:focus {
  outline: 1px solid #3a0ca3;
}

input[type="password"] {
  font-weight: 600;
}

button {
  font-family: "moderat-medium";
  cursor: pointer;
  outline: none;
}

button:disabled {
  /* cursor: not-allowed !; */
  opacity: 0.8;
}
/* Remove the default angle icon in select dropdown */

select {
  /* For Firefox */
  -webkit-appearance: none;
  /* for Chrome */
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

/*  */
.form-group {
  position: relative;
}
/*  */
/*  */
.none {
  display: none;
}

.below-table-loader-wrapper {
  margin-top: 20px;
}

.table-top-loader-wrapper {
  display: flex;
  justify-content: flex-end;
}

/* For Substitute Loader */
.below-table-loader-sub-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.upload-loading-spinner-wrapper {
  width: 15px;
  height: 15px;
  border: 4px solid #cccccc; /* Light grey */
  border-top: 4px solid #3a0ca3; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  z-index: 40;
  margin: 0 4px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Removing rechart default keys */
.recharts-legend-wrapper {
  display: none !important;
}

/* Modals */
.overflow-hidden {
  overflow: hidden;
  height: 100vh;
  margin-top: 0 !important;
}

.gray-overlay {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 50;
  transition: all ease-in-out 0.5s;
}

.secondary-button-wrapper button {
  background: #ede9f7;
  color: #3a0ca3;
  font-weight: 500;
  border: none;
}

/* Message handler wrapper */
.app--actions-handler-wrapper {
  top: 0px !important;
  right: 30px !important;
}
