.settlements-filter-container {
  position: relative;
  border-bottom: 1px solid #a9a9bc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settlements-filter-option {
  position: relative;
  width: 180px;
  padding: 12px 0;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  line-height: 20px;
  color: #a9a9bc;
}

.filter-option--all {
  margin-left: 50px;
}

.active-filter-option {
  color: #1a0404;
}

.active-filter-option::after {
  content: "";
  position: absolute;
  width: 180px;
  height: 4px;
  left: 0;
  bottom: 0;
  /* Gradient */
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
}

/* settlements-main-container--row-one */
.settlements-main-container--row-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

/* orders-table-filter-option-wrapper */
.settlements-table-filter-option-wrapper {
  display: flex;
  align-items: center;
}

.settlements-table-filter-row-title {
  font-family: "moderat-medium";
  /* font-size: 15px; */
  line-height: 15px;
  color: #a9a9bc;
  margin-right: 10px;
}

.settlements-table-filter-option-wrapper .form-group {
  margin-left: 20px;
}

.settlements-table-filter-option-wrapper .select-dropdown-wrapper {
  background-color: #f8f8fa;
}

.settlements-table-filter-option-wrapper .form-group select {
  width: 150px;
  background: #f8f8fa;
  border: none;
  color: #dadada;
  background-color: transparent;
  position: relative;
  z-index: 4;
}

.settlements-table-filter-option-wrapper select option {
  font-size: 14px;
  color: #0d0520;
}

.settlements-table-filter-option-wrapper .form-group .dropdown-icon img {
  top: 35%;
  z-index: 1;
}

.settlements-pending-wrapper {
  color: #ecbe1c !important;
}

.settlements-completed-wrapper {
  color: #00ba07 !important;
}

.td-pay-settlement--wrapper button,
.td-view-invoice--wrapper button,
.td-pay-settlement--wrapper span {
  align-items: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  line-height: 15px;
  outline: none;
  width: 120px;
  text-decoration: none;
}

.td-pay-settlement--wrapper button {
  background-color: rgba(58, 12, 163, 0.9);
  color: #fff;
}

.td-view-invoice--wrapper button {
  background: rgba(0, 186, 7, 0.1);
  color: #00ba07;
}

.td-pay-settlement--wrapper span {
  color: #3a0ca3;
  cursor: default;
}
/*  */
/*  */
/*  */
.none {
  display: none;
}
