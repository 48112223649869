.deals-table--top-wrapper {
  margin-top: 12px;
  display: flex;
  align-items: center;
  column-gap: 25px;
  justify-content: flex-end;
}

/* Edit Deal Row Column  */
.td-edit-deal-row--wrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 31px;
  background: #ede9f7;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.deals--top-btn-wrapper button {
  width: 180px;
  font-size: 14px !important;
}
