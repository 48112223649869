.select-companies-to-access-container {
  margin-top: 45px;
}

.toggle-form-section-button-wrapper button {
  display: flex;
  align-items: center;
  background-color: inherit;
  border: none;

  font-family: "moderat-medium";
  font-size: 14px;
  line-height: 17px;
  color: #707070;
}

.toggle-form-section-button-wrapper button img {
  transition: all 0.35s cubic-bezier(0, 1, 0, 1);
}

.icon-rotated-up {
  transform: rotate(180deg);
}

.select-companies-to-access-form-section {
  width: 361px;
  height: 320px;
  /* height: auto; */
  max-height: 9999px;
  background: #e6e6e6;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(1, 0, 1, 0);
}

.select-companies-to-access-form-section.collapsed {
  max-height: 0;
  transition: all 0.35s cubic-bezier(0, 1, 0, 1);
}

.select-companies-to-access-form-section--inner {
  padding: 12px;
}

.selected-companies-overview-wrapper {
  max-width: 850px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 25px;
  margin: 11.5px 0 8px;
}

.selected-companies-overview-wrapper .primary--selected-object-wrapper {
  padding: 5px 10px;
}

.primary--selected-object-wrapper {
  min-width: 96px;
  width: max-content;
  min-height: 20px;
  height: max-content;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 6px 12px;
  background: #e6e6e6;
  border-radius: 4px;
  column-gap: 6px;
}

.primary--selected-object-wrapper .left-col {
  display: flex;
  flex-direction: column;
}

.primary--selected-object-wrapper .left-col .object-main-text {
  font-family: "moderat-medium";
  line-height: 150%;
  color: #2e2e2e;
}

.primary--selected-object-wrapper .left-col .object-sub-text {
  font-family: "moderat-semibold";
  line-height: 150%;
  color: #707070;
}
.primary--selected-object-wrapper .right-col {
  margin-top: 5px;
}

.primary--selected-object-wrapper .right-col button {
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  border: none;
}

.selected-companies-overview-wrapper img {
  height: 24px;
}

.select-companies-to-access-form-section .form-search-wrapper {
  position: relative;
}

.select-companies-to-access-form-section .form-search-wrapper img {
  position: absolute;
  height: 23px;
  top: 7px;
  left: 11px;
}

.select-companies-to-access-form-section .form-search-wrapper input {
  width: 337px;
  height: 36px;
  border: 2px solid #c4c4c4;
  border-radius: 8px;
  padding-left: 40px;
  padding-right: 12px;
  font-size: 14px;
  background-color: inherit;
}

.select-companies-to-access-form-section .form-search-wrapper input:focus {
  outline: 0.1px solid #c4c4c4;
}

.select-entity-checkboxes-wrapper {
  max-height: 224px;
  overflow: auto;
  /* margin: 0 12px; */
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 16px;

  /* Text Styles */
  font-family: "moderat-medium";
  line-height: 19px;
  color: #2e2e2e;
}

.select-entity-checkboxes-wrapper::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

.select-entity-checkboxes-wrapper::-webkit-scrollbar {
  width: 4px;
  background-color: #c4c4c4;
}

.select-entity-checkboxes-wrapper::-webkit-scrollbar-thumb {
  background-color: #2e2e2e;
}

.select-entity-checkboxes-wrapper::-webkit-scrollbar-track,
.select-entity-checkboxes-wrapper::-webkit-scrollbar,
.select-entity-checkboxes-wrapper::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.select-companies-to-access-form-section {
  max-height: 9999px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(1, 0, 1, 0);
}

.select-companies-to-access-form-section.collapsed {
  max-height: 0;
  transition: all 0.35s cubic-bezier(0, 1, 0, 1);
}

.select-companies-to-access-form-section--inner {
  padding: 12px;
}

.select-companies-to-access-form-section .companies-checkbox {
  align-items: center;
  display: flex;
  color: #2e2e2e;
}

.select-companies-to-access-form-section input[type="checkbox"] {
  accent-color: #3a0ca3;
  background: inherit !important;
  height: 18.4px;
  outline: none;
  width: 18.4px;
}

.select-entity-action-btn-container .primary-button-container {
  margin: 15px 0 0;
}

.select-entity-action-btn-container .primary-button-container button {
  width: 150px;
  height: 45px;
  padding: 0;
  border-radius: 8px;
}
