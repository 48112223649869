.purchases-and-orders--top-container {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

/* purchases-and-orders--top--box-container */
.purchases-and-orders--top--box-container {
  width: 260px;
  height: 160px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 0 20px 30px;
  color: #0d0520;
}

.purchases-table--top-wrapper {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

/* Section.purchases-and-orders-table-section */
section.purchases-and-orders-table-section {
  margin-top: 60px;
}

/* purchases-and-orders-table--top-wrapper */
.purchases-and-orders-table--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Media queries */
@media (min-width: 1400px) {
  .purchases-and-orders-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .purchases-and-orders-container--inner {
    max-width: 1700px;
  }
}
