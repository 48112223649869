.stock-request-preview-container--inner {
}

.stock-request-preview-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stock-request-preview-top--left-wrapper {
  display: flex;
  align-items: center;
}

.stock-request-preview-top-container .back-button-wrapper button {
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

.stock-preview-top-title {
  font-family: "moderat-semibold";
  font-size: 26px;
  line-height: 50px;
  text-align: center;
  color: #1a0404;
  margin-left: 25px;
}

/* Stock Preview Row One Section / Merchant Info Section */
.stock-preview--row-one-section {
  margin-top: 30px;
  margin-right: 20px;
}

.stock-preview--row-one-section--inner {
  width: 100%;
  min-height: 265px;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  column-gap: 20px;
  padding: 35px 40px 29px 55px;
}

/* .stock-preview--row-one--left-wrapper { */
.stock-preview--row-one--left-wrapper {
  display: flex;
  align-items: center;
}

.stock-preview--merchant-img-wrapper {
  width: 175px;
  height: 175px;
  background: #d9d9d9;
  border-radius: 50%;
  overflow: hidden;
}

.stock-preview--merchant-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.stock-preview--merchant-shop-name {
  min-width: 108px;
  width: min-content;
  max-width: 170px;
  font-family: "moderat-medium";
  font-size: 24px;
  line-height: 29px;
  color: #2e2e2e;
  margin-left: 40px;
}

/* .stock-preview--row-one--right-wrapper */
.stock-preview--row-one--right-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 30px;
}

.stock-request-preview--merchant-detail-wrapper {
  width: 180px;
  flex-shrink: 0;
}

.stock-request-preview--email {
  width: 250px;
}

.info-detail-title {
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  color: #a9a9bc;
}

.info-detail-value {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #2e2e2e;
  margin-top: 6px;
}

.cac-document-link--wrapper a,
.no-document-link--wrapper {
  display: inline-block;
  width: 206px;
  height: 29px;
  text-decoration: none;
  background: #ede9f7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #2e2e2e;
}

/* Stock Preview Row Two Section / Merchant Info Section */
.stock-preview--row-two-section {
  margin-top: 100px;
  margin-right: 20px;
}

.stock-preview--row-two-title-wrapper {
  font-family: "moderat-semibold";
  font-size: 16px;
  line-height: 17px;
  color: #2e2e2e;
}

.other-stock-awaiting-approval-table-container {
  margin-top: 20px;
}
