.deals-form-container {
  margin-top: 50px;
  padding: 0 0.5px;
}

.deals-form-container form {
  max-width: 900px;
}

.deals-form-container .form-group-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

/* All inputs */
.deals-form-container form .form-group input,
.deals-form-container .form-group select,
.deals-form-container .form-group textarea {
  width: 400px;
}

.deal-form-primary-btn-container {
  max-width: 400px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.deal-setup-form--action-btn button {
  width: 220px;
  font-size: 14px;
}
