/* Company Staff Table Container */
.company-staff-table-container {
  margin-top: 19px;
}

.company-staff-table {
  width: 100%;
  background-color: #f0ecf8;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.company-staff-table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.company-staff-table thead tr {
  border-radius: 5px 5px 0px 0px;
}

/* Table Head , Table Row, Thable header*/
.company-staff-table thead tr th {
  padding: 20px 10px;
  font-family: "moderat-semibold";
  font-size: 14px;
  line-height: 12px;
  color: #3a0ca3;
}

.company-staff-table thead tr th:nth-child(1) {
  padding-left: 50px;
}

/* Table Body */
.company-staff-table tbody {
  margin: 0;
}

.company-staff-table tbody tr {
  overflow: hidden;
}

.company-staff-table tbody tr:hover td {
  background-color: #f8f8fa;
}

/* Table Body , Table Data*/
.company-staff-table tbody tr td {
  background-color: #ffffff;
  padding: 18px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  border-bottom: 1px solid #f3f3f3 !important;
}

.company-staff-table tbody tr:nth-last-child(1) td {
  border-bottom: none !important;
}

.company-staff-table tbody tr td:nth-child(1) {
  padding-left: 45px;
}

/*  Name Wrapper */
.company-staff-table .td-employee-name--wrapper {
  display: flex;
  align-items: center;
  max-width: 300px;
}
