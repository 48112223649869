.requests-filter-container {
  position: relative;
  border-bottom: 1px solid #a9a9bc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.requests-filter-option {
  position: relative;
  width: 180px;
  padding: 12px 0;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  line-height: 20px;
  color: #a9a9bc;
}

/* merchant-requests-table-section */
.merchant-requests-table-section,
.company-requests-table-section,
.stock-requests-table-section,
.stock-request-preview-container {
  margin-top: 30px;
}

.requests--actions-handler-wrapper {
  top: 80px !important;
  right: 30px !important;
}

/*  */
.item-rejected-or-declined-wrapper {
  width: 120px;
  color: #ce2e2e;
  font-size: 16px;
  text-align: center;
}

/* Media queries */
@media (min-width: 1400px) {
  .requests-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .requests-container--inner {
    max-width: 1700px;
  }
}
