.sidebar-container {
  position: fixed;
  top: 0;
  background-color: #eaeaf6;
  z-index: 1;
  /* left: 0; */
}

.sidebar-container--inner {
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  width: 260px;
  min-height: 100vh;
}

.company-logo {
  padding-left: 40px;
}

.company-logo img {
  height: 55px;
}

/* Navigation Links Container */
.navigation-links-container {
  padding-top: 70px;
  max-height: 95vh;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.navigation-links-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.navigation-links-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-link-container {
  margin-bottom: 10px;
}

.nav-link-container a {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 10px 0 45px;
  font-family: "moderat-medium";
  font-size: 17px;
  line-height: 28px;
  margin-bottom: 4px;
  background: inherit;
  color: #555555;
}

.nav-link-container a img {
  padding-right: 20px;
}

.active-link-wrapper a {
  background: #ffffff;
  color: #1e1e91;
}

.logout-button-wrapper {
  position: absolute;
  bottom: 30px;
  padding-left: 40px;
}
.logout-button-wrapper button {
  display: flex;
  align-items: center;
  border: none;
  background-color: inherit;
  font-size: 17px;
  color: #555555;
}

.logout-button-wrapper img {
  padding-right: 10px;
}

/* Media Queries */
@media (min-width: 1900px) {
  .sidebar-container--inner {
    width: 320px;
  }
}

@media (min-width: 2100px) {
  .sidebar-container--inner {
    width: 380px;
  }
}

@media (max-height: 700px) {
  .nav-link-container {
    margin-bottom: 7px;
  }

  .nav-link-container a {
    height: 48px;
  }
}

/* Media Queries */
@media (max-height: 655px) {
  .nav-link-container {
    margin-bottom: 5px;
  }
}
