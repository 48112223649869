/* salary-advance-requests-table-filter-option-wrapper */
.salary-advance-requests-table-filter-option-wrapper {
  display: flex;
  align-items: center;
}

.salary-advance-requests-table-filter-row-title {
  font-family: "moderat-medium";
  /* font-size: 15px; */
  line-height: 15px;
  color: #a9a9bc;
  margin-right: 10px;
}

.salary-advance-requests-table-filter-option-wrapper .form-group {
  margin-left: 20px;
}

.salary-advance-requests-table-filter-option-wrapper .select-dropdown-wrapper {
  background: #ffffff;
}

.salary-advance-requests-table-filter-option-wrapper .form-group select {
  width: 150px;
  border: none;
  color: #dadada;
  background-color: transparent;
  position: relative;
  z-index: 4;
}

.salary-advance-requests-table-filter-option-wrapper select option {
  font-size: 14px;
  color: #0d0520;
  z-index: 1;
}

.salary-advance-requests-table-filter-option-wrapper
  .form-group
  .dropdown-icon
  img {
  top: 18px;
}

/* Withdrawal Requests Table Container */
.withdrawal-requests--table-container {
  margin-top: 40px;
}

.withdrawal-requests--table {
  width: 100%;
  background-color: #f0ecf8;
  margin-top: 25px;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.withdrawal-requests--table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.withdrawal-requests--table thead tr {
  border-radius: 5px 5px 0px 0px;
}

/* Table Head , Table Row, Thable header*/
.withdrawal-requests--table thead tr th {
  padding: 20px 10px;
  font-family: "moderat-semibold";
  font-size: 14px;
  line-height: 12px;
  color: #3a0ca3;
}

.withdrawal-requests--table thead tr th:nth-child(1) {
  padding-left: 50px;
}

/* Table Body */
.withdrawal-requests--table tbody {
  margin: 0;
}

.withdrawal-requests--table tbody tr {
  overflow: hidden;
}

.withdrawal-requests--table tbody tr:hover td {
  background-color: #f8f8fa;
}

/* Table Body , Table Data*/
.withdrawal-requests--table tbody tr td {
  background-color: #ffffff;
  padding: 18px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  border-bottom: 1px solid #f3f3f3 !important;
}

.withdrawal-requests--table tbody tr:nth-last-child(1) td {
  border-bottom: none !important;
}

.withdrawal-requests--table tbody tr td:nth-child(1) {
  padding-left: 45px;
}

/*  Name Wrapper */
.withdrawal-requests--table .td-employee-name--wrapper {
  display: flex;
  align-items: center;
  max-width: 300px;
}

.td-email--wrapper {
  max-width: 180px;
  word-wrap: break-word;
}

.td-decline-request--wrapper button,
.td-approve-request--wrapper button,
.fill-otp-button-wrapper button {
  width: 120px;
  height: 32px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
}

.td-decline-request--wrapper button img,
.td-approve-request--wrapper button img {
  margin-right: 5px;
}

.td-decline-request--wrapper button {
  background: rgba(206, 46, 46, 0.1);
  color: #ce2e2e;
}

.td-approve-request--wrapper button,
.fill-otp-button-wrapper button {
  background: rgba(0, 186, 7, 0.1);
  color: #00ba07;
}
