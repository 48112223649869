.product-categories-table--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Add New category Button Wrapper */
.add-new-category-button-wrapper button {
  width: 250px;
  height: 50px;
  background: #3a0ca3;
  border-radius: 10px;
  font-size: 15px;
  color: #ffffff;
  border: none;
}

/* Products Table Container */
.products--table-container {
  margin-top: 40px;
}

.products--table {
  width: 100%;
  margin-top: 25px;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.products--table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.products--table thead tr {
  border-radius: 5px 5px 0px 0px;
}

/* Table Head , Table Row, Thable header*/
.products--table thead tr th {
  padding: 20px 10px;
  font-family: "moderat-semibold";
  font-size: 14px;
  line-height: 12px;
  color: #3a0ca3;
}

.products--table thead tr th:nth-child(1) {
  padding-left: 50px;
}

/* Table Body */
.products--table tbody {
  margin: 0;
}

.products--table tbody tr {
  overflow: hidden;
}

/* Table Body , Table Data*/
.products--table tbody tr td {
  background-color: #f8f8fa;
  padding: 18px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  border-bottom: 1px solid #f3f3f3 !important;
}

.products--table tbody tr:nth-last-child(1) td {
  border-bottom: none !important;
}

.products--table tbody tr td:nth-child(1) {
  padding-left: 45px;
}

/*  Name Wrapper */
.products--table .td-name--wrapper {
  display: flex;
  align-items: center;
  max-width: 300px;
}

.td-edit-button--wrapper button,
.td-delete-button--wrapper button {
  width: fit-content;
  height: 29px;
  border-radius: 4px;
  border: none;
  display: flex;
  column-gap: 6px;
  align-items: center;
  justify-content: space-around;
  padding: 0 18px;
  font-family: "moderat-medium";
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
}

.td-edit-button--wrapper button {
  background: #ede9f7;
  color: #3a0ca3;
}

.td-delete-button--wrapper button {
  background: rgba(206, 46, 46, 0.1);
  color: #ff4848;
}
