.employer-preview-container {
  position: relative;
}

/* Section.employer-preview-top-section */
section.employer-preview-top-section {
  /* padding-top: 30px; */
}

.employer-preview-top--left-wrapper {
  display: flex;
  align-items: center;
  /* column-gap: 265px; */
  justify-content: space-between;
}

.employer-preview-top--left__left {
  display: flex;
  align-items: center;
  column-gap: 30px;
  justify-content: space-between;
}

.suspend-unsuspend-company-buttons-wrapper {
  display: flex;
  align-items: center;
}

.update-free-trial-button-wrapper button,
.suspend-unsuspend-company-buttons-wrapper button {
  width: 220px;
  height: 50px;
  border: none;
  border-radius: 10px;
  font-size: 15px;
}

.suspend-unsuspend-company-buttons-wrapper button {
  color: #ffffff;
}

.suspend-company-button {
  background: #ce2e2e;
}

.unsuspend-company-button {
  background: #3a0ca3;
}

.employer-preview-back-button-wrapper button {
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
}

/* employer-preview-top-boxes-container */
.employer-preview-top-boxes-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
  row-gap: 30px;
  margin-top: 28px;
  padding: 0 55px;
}

.employer-preview-box-wrapper {
  min-width: 236px;
  max-width: 260px;
  width: min-content;
  height: 143px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 0 20px 30px;
  color: #0d0520;
}

.copy-to-clipboard-button-wrapper button {
  border: none;
  width: 21px;
  height: 21px;
  background-color: transparent;
  margin-left: 5px;
}

/* section.employer-preview-information-section  */
section.employer-preview-information-section {
  margin-top: 55px;
}

.section-title-wrapper {
  font-family: "moderat-medium";
  line-height: 17px;
  color: #2e2e2e;
}

/* .employer-preview-information-container */
.employer-preview-information-container {
  width: 100%;
  min-height: 265px;
  height: min-content;
  display: flex;
  column-gap: 60px;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 35px 45px 35px 55px;
}

/* Left Wrapper */
.employer-preview-information--left-wrapper {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.employer-info--company-picture-wrapper {
  position: relative;
  width: 175px;
  height: 175px;
  background: #d9d9d9;
  border-radius: 50%;
  overflow: hidden;
}

.employer-info--company-picture-wrapper img {
  position: absolute;
  width: 150%;
  height: 150%;
  object-fit: contain;
  top: -25%;
  left: -25%;
}

.employer-info--company-name-wrapper {
  max-width: 130px;
  width: min-content;
  font-family: "moderat-medium";
  font-size: 24px;
  line-height: 29px;
  color: #2e2e2e;
}

/* loan-access--value-wrapper */
.loan-access--value-wrapper {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

/* loan-access-display-input-wrapper */
.loan-access-display-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 8px;
}

.single-loan-aceess-wrapper input {
  accent-color: #3a0ca3;
  transform: translateY(2px);
  width: 16px;
  height: 16px;
  margin-right: 5px;
  outline: none;
}

/* update-loan-access-button-wrapper */
.update-loan-access-button-wrapper {
  display: flex;
  align-items: center;
}

.update-loan-access-button-wrapper button {
  width: 180px;
  height: 40px;
  background: #3a0ca3;
  border-radius: 8px;
  border: none;
  color: #ffffff;
}

.update-loan-access-button-wrapper button:disabled {
  opacity: 0.9;
  cursor: not-allowed;
}

/* Right Wrapper */
.employer-preview-information--right-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 70px;
  row-gap: 20px;
}

.company-info-detail-wrapper {
  width: 180px;
  flex-shrink: 0;
}

.company-info--key-wrapper {
  font-family: "moderat-medium";
  font-size: 13px;
  line-height: 12px;
  color: #a9a9bc;
}

.company-info--value-wrapper {
  font-family: "moderat-medium";
  font-size: 15px;
  line-height: 16px;
  color: #2e2e2e;
  margin-top: 5px;
  word-wrap: break-word;
}

/* Section.employer-preview-staff-section */
section.employer-preview-staff-section {
  margin-top: 55px;
}

/* loan-percent-input-box-wrapper */
.loan-percent-input-box-wrapper {
  padding: 20px 20px 5px 20px;
  color: #0d0520;
}

.loan-percent-form-group input {
  height: 35px !important;
  border-radius: 8px !important;
}

/* Chrome, Safari, Edge, Opera */
.loan-percent-form-group input::-webkit-outer-spin-button,
.loan-percent-form-group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.loan-percent-form-group input[type="number"] {
  -moz-appearance: textfield;
}

/*  */
.employer-preview--actions-handler-wrapper {
  top: -20px !important;
  right: 20px !important;
}

.submit-loan-percentage-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
}

.submit-loan-percentage-button-wrapper button {
  width: 140px;
  height: 40px;
  background: #3a0ca3;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  margin-left: 8px;
}

/* Media queries */
@media (min-width: 1400px) {
  .requests-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .requests-container--inner {
    max-width: 1700px;
  }
}
