.admins-table--top-wrapper {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
}

/* Edit Deal Row Column  */
.td-edit-deal-row--wrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 31px;
  background: #ede9f7;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
