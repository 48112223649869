.form-header-wrapper {
  width: 460px;
  height: 27px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: #f8f8fa;
  font-size: 14px;
  line-height: 14px;
  color: #3a0ca3;
  margin-bottom: 25px;
}

.admin-settings-form-container form .form-group {
  margin-bottom: 25px;
}

.admin-settings-form-container .form-group input,
.admin-settings-form-container .form-group select {
  width: 500px;
}

.currency-rates-input::-webkit-outer-spin-button,
.currency-rates-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.currency-rates-input[type="number"] {
  -moz-appearance: textfield;
}
