.admin-form--top-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.administrators-form-container {
  margin-top: 50px;
  padding: 0 0.5px;
}

.administrators-form-container form {
  max-width: 900px;
}

.administrators-form-container .form-group-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}

/* All inputs */
.administrators-form-container form .form-group input,
.administrators-form-container .form-group select {
  width: 400px;
}

#admin-phone-number-text-input {
  padding-left: 55px;
}

.admin-form-primary-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 70px;
}

.admin-setup-form--action-btn button {
  width: 220px;
  font-size: 14px;
}
