/* Orders Table Container */
.orders--table-container {
  margin-top: 40px;
}

.orders--table {
  width: 100%;
  background-color: #f0ecf8;
  margin-top: 25px;
  border-radius: 10px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.orders--table thead {
  background-color: #f0ecf8;
}
/* Table Head , Table Row */
.orders--table thead tr {
  border-radius: 5px 5px 0px 0px;
}

/* Table Head , Table Row, Thable header*/
.orders--table thead tr th {
  padding: 20px 10px;
  font-family: "moderat-semibold";
  font-size: 14px;
  line-height: 12px;
  color: #3a0ca3;
}

.orders--table thead tr th:nth-child(1) {
  padding-left: 50px;
}

/* Table Body */
.orders--table tbody {
  margin: 0;
}

.orders--table tbody tr {
  overflow: hidden;
}

.orders--table tbody tr:hover td {
  background-color: #f8f8fa;
}

/* Table Body , Table Data*/
.orders--table tbody tr td {
  background-color: #ffffff;
  padding: 18px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  border-bottom: 1px solid #f3f3f3 !important;
}

.orders--table tbody tr:nth-last-child(1) td {
  border-bottom: none !important;
}

.orders--table tbody tr td:nth-child(1) {
  padding-left: 45px;
}

.order-pending-wrapper {
  color: #ecbe1c !important;
}

.order-completed-wrapper {
  color: #00ba07 !important;
}

.order-cancelled-wrapper {
  color: #b5179e;
}

.td-items--wrapper {
  color: #3a0ca3;
}
