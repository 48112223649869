.user-preview-container {
  font-family: "moderat-regular";
  position: relative;
  padding: 0 0 30px;
}

.user-preview-container--inner {
  position: relative;
  max-width: 1380px;
  margin: auto;
  overflow: hidden;
}

/* Section.user-preview-top-section */
section.user-preview-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activate-delete-user-buttons-wrapper {
  display: flex;
  align-items: center;
}

.activate-delete-user-buttons-wrapper button {
  width: 250px;
  height: 50px;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  font-size: 15px;
}

.delete-user-btn {
  background: #ce2e2e;
}

.activate-user-btn {
  background: #3a0ca3;
}

.user-preview-back-button-wrapper button {
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  border: none;
}

/* section.user-preview-information-section  */
section.user-preview-information-section {
  margin-top: 55px;
}

.section-title-wrapper {
  font-family: "moderat-medium";
  line-height: 17px;
  color: #2e2e2e;
}

/* .user-preview-information-container */
.user-preview-information-container {
  width: 100%;
  min-height: 265px;
  height: min-content;
  display: flex;
  column-gap: 60px;
  background: #ffffff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 35px 45px 35px 55px;
}

/* Left Wrapper */
.user-preview-information--left-wrapper {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.user-info--company-picture-wrapper {
  position: relative;
  width: 175px;
  height: 175px;
  background: #d9d9d9;
  border-radius: 50%;
  overflow: hidden;
}

.user-info--company-picture-wrapper img {
  position: absolute;
  width: 150%;
  height: 150%;
  object-fit: contain;
  top: -25%;
  left: -25%;
}

.user-info--company-name-wrapper {
  max-width: 130px;
  width: min-content;
  font-family: "moderat-medium";
  font-size: 24px;
  line-height: 29px;
  color: #2e2e2e;
}

/* Right Wrapper */
.user-preview-information--right-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 70px;
  row-gap: 20px;
}

/* Section.user-preview-recent-purchases-section */
section.user-preview-recent-purchases-section {
  margin-top: 55px;
}

/*  */
.user-preview--actions-handler-wrapper {
  top: 15px !important;
  right: 20px !important;
}
