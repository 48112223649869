.td-user-name--wrapper {
  display: flex;
  align-items: flex-end;
}

.td-user-name--wrapper span {
  font-family: "moderat-medium";
  font-size: 12px;
  color: #b5179e;
  padding-left: 6px;
}
