/* on-demand-requests-table-filter-option-wrapper */
.on-demand-requests-table-filter-option-wrapper {
  display: flex;
  align-items: center;
}

.on-demand-requests-table-filter-row-title {
  font-family: "moderat-medium";
  /* font-size: 15px; */
  line-height: 15px;
  color: #a9a9bc;
  margin-right: 10px;
}

.on-demand-requests-table-filter-option-wrapper .form-group {
  margin-left: 20px;
}

.on-demand-requests-table-filter-option-wrapper .select-dropdown-wrapper {
  background: #ffffff;
}

.on-demand-requests-table-filter-option-wrapper .form-group select {
  width: 150px;
  border: none;
  color: #dadada;
  background-color: transparent;
  position: relative;
  z-index: 4;
}

.on-demand-requests-table-filter-option-wrapper select option {
  font-size: 14px;
  color: #0d0520;
  z-index: 1;
}

.on-demand-requests-table-filter-option-wrapper .form-group .dropdown-icon img {
  top: 18px;
}
