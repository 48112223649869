.remove-selected-voucher-button-wrapper button {
  max-width: 270px;
  min-width: 260px;
  width: 100%;
  height: 32px;
  padding: 0 15px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
}

.remove-selected-voucher-button-wrapper button {
  background: rgba(206, 46, 46, 0.1);
  color: #ce2e2e;
}
