.add-new-company-container {
  background-color: #ffffff;
}

.add-new-company-container--inner {
  max-width: 2000px;
  margin: auto;
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  position: relative;
  overflow: hidden;
}

/* Add New User Left Section*/
.add-new-user-left-section {
  grid-column: span 7 / span 7;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

.add-new-user-left-section--inner {
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  margin-top: 10%;
  padding-bottom: 50px;
}

.add-new-user-back-button-wrapper button {
  width: 50px;
  height: 50px;
  background: #f8f8fa;
  border-radius: 10px;
  border: none;
}

.add-new-user-left-section-header {
  max-width: 364px;
  margin-top: 30px;
  font-family: "moderat-semibold";
  font-size: 40px;
  line-height: 54px;
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.add-new-user-left-section-sub-header {
  margin-top: 18px;
  line-height: 24px;
  color: #2e2e2e;
}

/* Form Wrapper */
.add-new-user-form-wrapper {
  margin-top: 70px;
}

.add-new-user-form-wrapper form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 40px;
}

.add-new-user-form-wrapper .form-group input {
  width: 300px;
}

.add-new-user-submit-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 50px;
}

.add-new-user-submit-button-wrapper button {
  width: 250px;
  height: 50px;
  background: #3a0ca3;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  line-height: 25px;
  margin-left: 10px;
}

/* Add New User Right Section*/
.add-new-user-right-section {
  grid-column: span 5 / span 5;
  background-color: #fbf9ff;
  overflow: hidden;
  position: relative;
}

.right-add-new-user-section-image-wrapper {
  position: absolute;
  bottom: -5px;
  right: 0;
}

.right-add-new-user-section-image-wrapper img {
  max-width: 517px;
}
