.purchase-deals-table-section {
  margin-top: 40px;
}

.open-search-modal-button-wrapper button,
.cancel-search-modal-button-wrapper button {
  width: 220px;
}

.cancel-search-modal-button-wrapper button {
  background: rgba(206, 46, 46, 0.1) !important;
  color: #ce2e2e !important;
}

.td-send-voucher-otp-button-wrapper button,
.td-redeem-voucher--button-wrapper button {
  width: 100%;
  height: 32px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "moderat-medium";
  font-size: 14px;
  line-height: 15px;
}

.td-send-voucher-otp-button-wrapper button {
  max-width: 250px;
  min-width: 210px;
  background: #ede9f7;
  color: #3a0ca3;
}

.td-redeem-voucher--button-wrapper button {
  max-width: 210px;
  min-width: 170px;
  background: rgba(0, 186, 7, 0.1);
  color: #00ba07;
}

.vouchers-to-be-redeemed-table-filter-wrapper select {
  width: 170px !important;
}

.redeemed-voucher-status {
  display: flex;
  justify-content: center;
  color: #3a0ca3;
}
