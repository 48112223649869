.wellness--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wellness--top-action-btns-container {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.add-new-course-btn-wrapper button {
  width: 230px;
  font-size: 14px !important;
}

.course-publish-status-filter-container {
  margin-top: 20px;
}

.wellness-courses-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-top: 40px;
}

button.wellness-courses-wrapper {
  position: relative;
  /* width: 248px; */
  min-height: 222px;
  padding-bottom: 16px;
  background: #f0f0f0;
  border-radius: 12px;
  border: none;
  text-align: left;
  font-size: 16px;
  overflow: hidden;
}

.course-image-wrapper {
  position: relative;
  /* width: 248px; */
  height: 140px;
}

.course-image-wrapper img {
  width: 115%;
  transform: translateX(-5%);
  height: 100%;
  object-fit: cover;
}

.course-box--lower {
  margin-top: 10px;
  padding: 0 14px;
}

.course-box--name {
  font-weight: 500;
  line-height: 150%;
  color: #2e2e2e;
}

.course-card-footer {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.course-box--footer-count {
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin-top: 12px;
  color: #707070;
}

.wellness--title-wrapper {
  font-weight: 700;
  line-height: 150%;
  color: #2e2e2e;
}

.wellness--title-wrapper span {
  color: #707070;
}

/* Media queries */
@media (max-width: 1410px) {
  /* 3 elements on grid */
  .wellness-courses-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .course-card-footer {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
}

@media (max-width: 1110px) {
  /* 2 elements on grid */
  .wellness-courses-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 840px) {
  /* 1 elements on grid */
  .wellness-courses-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
