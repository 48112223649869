/* Customer Form Top Wrapper */
.add-course-form--top-nav-container {
  border-bottom: 2px solid #a9a9bc;
  margin-top: 40px;
}

.add-course-form--top-nav-container--inner {
  max-width: 1260px;
  margin: auto;
  display: flex;
}

.add-course-form--form-pages-link-wrapper {
  transform: translateY(2px);
}

.add-course-form--form-pages-link-wrapper button {
  width: 305px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  padding: 32px 0 16px;
  border: none;
  background-color: inherit;
  font-family: "moderat-medium";
  font-size: 16px;
  color: #2e2e2e;
}

.active-form-page button {
  background: #eae8ed;
  border-bottom: 3px solid #7741f1;
  color: #3a0ca3;
}

.page-number-wrapper {
  width: 31px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 24px;
  color: #2e2e2e;
}

.active-form-page button .page-number-wrapper,
.page-form-filled button .page-number-wrapper {
  background: rgba(171, 171, 220, 0.3);
  color: #3a0ca3;
}

.page-form-filled button {
  color: #1e1e91;
}

/* Form Section */
.course---form-section {
  padding-left: 1px;
}

.course---form-section input::-webkit-inner-spin-button,
.course---form-section input::-webkit-outer-spin-button {
  appearance: none;
}

.form-section-title-label {
  margin: 40px 0 16px;
  font-family: "moderat-semibold";
  line-height: 150%;
  color: #707070;
}

.course-content-action-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 30px;
  margin-top: 50px;
}

.course-content-action-btn-container .primary-button-container {
  margin-top: 0;
}

.course-content-form--action-btn button {
  width: 250px;
  font-size: 14px;
}

.course-form--submit-btn-wrapper {
  margin-top: 60px;
  display: flex;
  justify-content: flex-end;
}

.course-form--submit-btn-wrapper button {
  width: 250px;
  font-size: 14px;
}

/* Course Assessment form */
.course-assessment--form-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.course-form-radio-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 32px;
  row-gap: 25px;
}

.course-form-radio-input-wrapper .radio-form-group {
  /* min-width: 120px; */
  /* background-color: red; */
  display: flex;
  align-items: center;
  column-gap: 6px;
  /* Text Input */
  font-weight: 400;
  font-size: 16px;
  color: #1a1a1a;
}

.course-form-radio-input-wrapper .form-group input {
  height: min-content;
  width: min-content;
}

/* Media Queries */
@media (max-width: 1520px) {
  .add-course-form--form-pages-link-wrapper button {
    width: 280px;
    height: 70px;
  }
}

@media (max-width: 1390px) {
  .add-course-form--form-pages-link-wrapper button {
    width: 250px;
    height: 60px;
    padding: 28px 0 12px;
  }
}
