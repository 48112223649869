/* Decline Requests Modal */
.product-and-categories-modal-container {
  position: absolute;
  width: 375px;
  min-height: 295px;
  z-index: 50;
  left: 40%;
  top: 25%;
  background: #ffffff;
  border-radius: 20px;
}

.product-and-categories-modal-container--inner {
  padding: 25px 35px;
}

.product-and-categories-modal-header {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
  width: 100%;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
  color: #3a0ca3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brand-and-category-name-form-wrapper form {
  width: 100%;
  margin-top: 30px;
}

.brand-and-category-name-form-wrapper form .form-group {
  width: 100%;
}

/* brand-and-category-name form group */
.brand-and-category-name-form-wrapper input {
  width: 100%;
}

/*  */
/*  Category Image file form group */
.category-image-file-form-group--inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

/* File Input Wrapper */
.category-image-file-form-group--inner .file-input-wrapper {
  min-width: 160px;
  max-width: 400px;
  min-height: 33px;
  position: relative;
  background-color: #ede9f7;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  /* word-wrap: break-word; */
}

/* File Input */
.category-image-file-form-group--inner .file-input-wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  outline: none;
}

/* File Input Title */
.category-image-file-form-group--inner .file-input-wrapper .file-input-title {
  height: 100%;
  font-family: "moderat-medium";
  font-size: 14px;
  line-height: 13px;
  color: #2e2e2e;
  padding: 0 3px;
}

.preview-category-image-icon a {
  margin-left: 8px;
}

.preview-category-image-icon a img {
  height: 16px;
  filter: invert(0.3);
}

/* Modal Actions Button Wrapper */
.product-and-categories-modal-action-buttons-wrapper {
  margin-top: 45px;
}

.submit-product-and-categories-btn-wrapper {
  display: flex;
  align-items: center;
}

.submit-product-and-categories-btn-wrapper button {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  background-color: #3a0ca3;
  color: #ffffff;
}
