.products-table--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.products--table .td-name--wrapper .profile-image-wrapper {
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 15px;
}

.products--table .td-name--wrapper .profile-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.make-hot-deal-button-wrapper button,
.remove-from-hot-deal-button-wrapper button {
  max-width: 250px;
  min-width: 220px;
  width: 100%;
  height: 32px;
  padding: 0 15px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
}

.make-hot-deal-button-wrapper button {
  background: rgba(0, 186, 7, 0.1);
  color: #00ba07;
}

.remove-from-hot-deal-button-wrapper button {
  background: rgba(206, 46, 46, 0.1);
  color: #ce2e2e;
}
