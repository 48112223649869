/* TODO New Layout structure has only been implemented for subscriptions and administrators pages */
.app-page-container {
  position: relative;
  overflow: hidden;
}

/* Inside Main */
.app-main-section--inner {
  position: relative;
  max-width: 1380px;
  /* display: flex; */
  margin: auto;
  overflow: hidden;
  padding-bottom: 60px;
}

.app-main-section--top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Media Queries */
@media (min-width: 1500px) {
  .app-main-section--inner {
    max-width: 1700px;
  }
}

@media (min-width: 1400px) {
  .app-main-section--inner {
    max-width: 1500px;
  }
}
