/* Course Categirty Top */
.course-category--top-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.course-category--top-action-btns-container {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.course-category--top-action-btns-container .primary-button-container {
  margin-top: 0;
}
