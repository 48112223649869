.awaiting-verification-container {
  position: relative;
  overflow: hidden;
  background-color: #f8f8fa;
  min-height: 100vh;
}

.awaiting-verification-wrapper {
  background-color: #f8f8fa;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.verification-token-form-container {
  max-width: 450px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.verification-token-form-group {
  max-width: 300px;
  width: 100%;
}

.verification-token-form-group input {
  width: 100%;
  background-color: #f8f8fa;
}

.verification-token-form-group input::-webkit-inner-spin-button,
.verification-token-form-group input::-webkit-outer-spin-button {
  appearance: none;
}

.verification-token-form-group label {
  text-align: left;
}

.submit-verification-token-button-wrapper {
  margin-top: 20px;
}

.submit-verification-token-button-wrapper button {
  width: 160px;
}

.resend-verification-link-text-wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  padding-top: 20px;
}

.resend-verification-link--button-wrapper,
.submit-verification-token-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resend-verification-link--button-wrapper {
  margin-top: 10px;
}

.resend-verification-link--button-wrapper button,
.submit-verification-token-button-wrapper button {
  background-color: #3a0ca3;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  font-family: "moderat-medium";
  font-size: 15px;
  line-height: 15px;
  color: #ffffff;
  cursor: pointer;
}

.resend-verification-link--button-wrapper button {
  width: 200px;
}

.verification-loader-wrapper {
  margin-left: 10px;
}

.verification-message-wrapper {
  top: 110px !important;
}

/*  */
/*  */

/* Section Header */
.dashboard-awaiting-data-section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
}

.dashboard-awaiting-data-section-header-title {
  max-width: 750px;
  font-family: "moderat-bold";
  font-size: 40px;
  line-height: 54px;
  margin: 5px 0 10px;
  text-align: center;
  background: linear-gradient(281.7deg, #b5179e 3.1%, #3a0ca3 96.25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: flex;
  align-items: center;
}

.dashboard-awaiting-data-section-header-title img {
  transform: translateY(4px);
  margin-left: 10px;
}

.dashboard-awaiting-data-section-header-sub-title {
  max-width: 350px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #2e2e2e;
  text-align: center;
}

/* Media Queries */
@media (max-width: 520px) {
  .awaiting-verification-wrapper {
    margin-top: 130px;
  }

  .dashboard-awaiting-data-section-header-title {
    font-size: 30px;
    line-height: 30px;
  }
}
