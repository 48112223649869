.notifications-form-container {
  margin-top: 40px;
  padding: 0 0.5px;
}

.notifications-form-container form {
  max-width: 900px;
}

.notifications-form-container .form-group-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}

/* All inputs */
.notifications-form-container form .form-group {
  width: 100%;
}

.notifications-form-container .form-group textarea {
  width: 100%;
  min-height: 450px;
  padding-top: 12px;
  resize: none;
}

.notifications-form-container .form-group textarea::placeholder {
  font-style: italic;
}

.notifications-form-primary-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.notifications-setup-form--action-btn button {
  width: 220px;
  font-size: 14px;
}
