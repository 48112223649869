/* ROW ONE SECTION */
.dashboard-row--one-section--inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 30px;
}

.dashboard-row-one-box-container {
  /* width: 354px; */
  min-height: 141px;
  background: #ffffff;
  border-radius: 20px;
  padding: 26px 18px 0px 23px;
}

.dashboard-row-one-box--bottom-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 42px;
}

.dashboard-box-title-wrapper {
  font-family: "moderat-bold";
  font-size: 13px;
  line-height: 12px;
  color: #0d0520;
}

.dahboard-box-value-wrapper {
  font-family: "moderat-medium";
  font-size: 36px;
  line-height: 38px;
  color: #3a0ca3;
}

.row--one-percent-change-wrapper {
  width: 75px;
  height: 39px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.percent-increase-wrapper {
  background-color: rgba(0, 186, 7, 0.1);
  color: #00ba07;
}

.percent-decrease-wrapper {
  background-color: rgba(226, 49, 49, 0.1);
  color: #e23131;
}

.row--one-percent-change-wrapper img {
  transform: translateY(1px);
  padding-left: 5px;
}

/* ROW TWO SECTION */
.dashboard-row-two-box-container {
  width: 100%;
  min-height: 390px;
  background: #ffffff;
  border-radius: 20px;
  padding: 26px 18px 0px 36px;
}

.dashboard-row-two-box--middle-wrapper {
  display: flex;
  align-items: center;
  margin-top: 16px;
  column-gap: 176px;
}

/* graph-keys-display-wrapper */
.graph-keys-display-wrapper {
  display: flex;
  align-items: center;
  column-gap: 40px;
}

.graph-key-wrapper {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.graph-key-icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.bnpl-key-icon {
  background: #ffb800;
}

.outright-purchase-key-icon {
  background: #ff3a29;
}

.graph-key-title {
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

/* Purchases chart container */
.purchases-chart-container {
  width: 97%;
  height: 250px;
  /* Text styles */
  padding-top: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 10px;
  color: #a9a9bc;
  margin-top: 28px;
}

/* ROW THREE */
.dashboard-row--three-section--inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 50px;
  row-gap: 50px;
}

.dashboard-row-three-table-box-container {
  width: 556px;
  min-height: 376px;
  background: #ffffff;
  border-radius: 20px;
  padding: 26px 25px 0 30px;
}

/* Dahboard Table Container */
.dashboard--table-container {
  margin-top: 40px;
}

.dashboard--table {
  width: 100%;
  background-color: #f0f0f0;
  margin-top: 30px;
  overflow: hidden;
  /* Remove table gaps and margins */
  border-spacing: 0px;
}

.dashboard--table thead {
  background: #f0f0f0;
}

/* Table Head , Table Row, Thable header*/
.dashboard--table thead tr th {
  padding: 15px 10px;
  font-family: "moderat-regular";
  font-size: 12px;
  line-height: 12px;
  color: #a9a9bc;
}

.dashboard--table thead tr th:nth-child(1) {
  padding-left: 24px;
}

/* Table Body */
.dashboard--table tbody {
  margin: 0;
}

.dashboard--table tbody tr {
  overflow: hidden;
}

.dashboard--table tbody tr:hover td {
  background-color: #fdfdfd;
}

/* Table Body , Table Data*/
.dashboard--table tbody tr td {
  background-color: #ffffff;
  padding: 18px 10px;
  font-family: "moderat-medium";
  color: #2e2e2e;
  font-size: 15px;
  border-bottom: 1px solid #f0f0f0 !important;
}

.dashboard--table tbody tr:nth-last-child(1) td {
  border-bottom: none !important;
}

.dashboard--table tbody tr td:nth-child(1) {
  padding-left: 24px;
}

/*  Name Wrapper */
.dashboard--table .td-company-name--wrapper {
  display: flex;
  align-items: center;
  max-width: 300px;
}

/* Media queries */
@media (min-width: 1400px) {
  .dashboard-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .dashboard-container--inner {
    max-width: 1700px;
  }
}

@media (max-width: 1125px) {
  .dashboard-row--one-section--inner {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 825px) {
  .dashboard-row--one-section--inner {
    grid-template-columns: repeat(1, 1fr);
  }
}
