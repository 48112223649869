.update-free-trial-modal-container {
  position: absolute;
  width: 398px;
  min-height: 119px;
  z-index: 50;
  left: 40%;
  top: 25%;
  background: #ffffff;
  border-radius: 20px;
}

.update-free-trial-modal-container--inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 35px 25px;
}

.update-free-trial-modal-container form {
  width: 100%;
  margin-top: 20px;
}

.update-free-trial-modal-container form .form-group,
.update-free-trial-modal-container form input {
  width: 100%;
}

.update-free-trial-modal-container form input[type="date"] {
  height: 35px;
  border-radius: 8px;
}

.is-enabled-checkbox-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 5px;
  margin-bottom: 20px;
}

.is-enabled-checkbox-wrapper input {
  accent-color: #3a0ca3;
  transform: translateY(0px);
  transform: translateX(-3px);
  width: 16px !important;
  height: 16px;
  margin-right: 5px;
  outline: none;
}

.modal__update-free-trial-button {
  margin-top: 30px;
}

.modal__update-free-trial-button button {
  width: 150px !important;
  height: 45px !important;
}
