.merchants--top-container {
  display: flex;
  align-items: center;
  column-gap: 44px;
}

/* merchants--top--box-container */
.merchants--top--box-container {
  width: 260px;
  height: 160px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 0 20px 30px;
  color: #0d0520;
}

/* Media queries */
@media (min-width: 1400px) {
  .merchants-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .merchants-container--inner {
    max-width: 1700px;
  }
}
