.course-preview--top-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.course-preview--top-action-btns-container {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.course-preview--top-action-btns-container .primary-button-container {
  margin-top: 0;
}

.preview-course--image-wrapper {
  max-width: 1246px;
  width: 100%;
  height: 245px;
  margin-top: 28px;
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
}

.preview-course--image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-course--course-info {
  margin-top: 20px;
}

.course-info--row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  line-height: 150%;
}

.course-info--course-title {
  font-family: "moderat-semibold";
  font-size: 24px;
  color: #2e2e2e;
}

.course-info--course-upload {
  font-family: "moderat-medium";
  color: #707070;
}

.course-info--course-detail {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-top: 24px;
  font-family: "moderat-medium";
  color: #707070;
}

.course-preview--course-description {
  margin-top: 60px;
  max-width: 850px;
}

.course-description--title,
.course-participants--title {
  font-family: "moderat-semibold";
  color: #707070;
}

.course-description--sub-title,
.course-participants--sub-title {
  padding: 6px 0 6px;
  color: #2e2e2e;
  font-family: "moderat-medium";
}

.course-participants--sub-title {
  padding: 16px 0 32px;
}

.course-description--value {
  margin-top: 12px;
  font-family: "moderat-medium";
  color: #2e2e2e;
}

.course-contents--value {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.course-contents--value-inner {
  display: flex;
  align-items: center;
  column-gap: 18px;
}

.course-preview--course-participants {
  margin-top: 60px;
}

.course-participants--value {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-top: 16px;
}

.participant-completion-wrapper {
  display: flex;
  align-items: center;
  column-gap: 32px;
  font-family: "moderat-medium";
}

.participant-name {
  width: 270px;
  color: #2e2e2e;
}

.participant-completion-level-wrapper {
  display: flex;
  align-items: center;
  column-gap: 14px;
  /* Text */
  font-family: "moderat-regular";
  font-size: 14px;
  line-height: 130%;
  color: #707070;
}

.participant-completion-bar {
  width: 80px;
  height: 4px;
  background: #d9d9d9;
  border-radius: 16px;
  overflow: hidden;
}

.completion-bar-level {
  height: 4px;
  background: #3a0ca3;
}
