.plan-details-form-section {
  max-width: 860px;
}

.plan-details--form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.plan-details--form-wrapper--grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}

.plan-details--form-wrapper--grid .form-group input {
  width: 400px !important;
}

.plan-details-form-group {
  width: 100%;
}

.plan-form-action-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 30px;
  margin-top: 50px;
}

.plan-form-action-btn-container .primary-button-container {
  margin-top: 0;
}

.plan-form--action-btn button {
  width: 250px;
  font-size: 14px;
}
