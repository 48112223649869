/* Course Content Options */
section.course-content-type-section {
  margin: 40px 0 16px;
}

.course-content-type-container {
  width: max-content;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 8px;
  background: #eff0f0;
  border-radius: 8px;
}

.course-content-type-container button {
  width: 147px;
  height: 42px;
  font-weight: 13px;
  font-weight: 400;
  border-radius: 8px;
  background-color: #eff0f0;
  color: #54565a;
}

.active-content-type-btn button {
  background: #3a0ca3;
  color: #ffffff;
}

/* Course Content Form */
.course-file-form-group-container {
  max-width: 724px;
  width: 100%;
}

.course-file-form-group-wrapper {
  margin-bottom: 30px;
}

/* Form Header and Action Container */
.form-header-container {
  position: relative;
}

.delete-form-object-wrapper {
  position: absolute;
  top: 0;
  right: -160px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  background-color: inherit;
  border: none;
  /* Text  Styles*/
  font-size: 16px;
  line-height: 135%;
  color: #54565a;
  cursor: pointer;
}

.course-file-form-group-container .form-header-action-wrapper {
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: #eff0f0;
  margin-bottom: 20px;
  /* Text Styles */
  font-family: "moderat-medium";
  font-size: 14px;
  line-height: 140%;
  color: #1a1a1a;
}

.course-file-form-group-container button.form-header-action-wrapper {
  border: none;
  column-gap: 4px;
}

/* UPLOAD COURSE CONTENT BOX(VIDEO || SLIDES) */
.upload-course-content-box-container {
  margin-top: 24px;
}

/* course-file-uploaded-wrapper */
.course-file-uploaded-wrapper {
  padding: 32px;
  padding-bottom: 16px;
  row-gap: 4px;
}

.course-file-uploaded--top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
}

.file-uploaded--course-title {
  width: 70%;
  font-family: "moderat-semibold";
  font-size: 24px;
  line-height: 30px;
  color: #2e2e2e;
  word-wrap: break-word;
}

.remove-uploaded-file-btn--wrapper button {
  border: none;
  background-color: transparent;
  padding: 0;
}

.file-uploaded--course-duration,
.file-uploaded--course-size {
  font-size: 20px;
  line-height: 150%;
  color: #707070;
}

/* Course Upload Box Wrapper */
.course-file-form-group-wrapper .form-group,
.course-file-form-group-wrapper input[type="text"] {
  width: 100%;
}

.course-image-form-wrapper {
  margin-top: 60px;
}

.course-file-form-group,
.course-file-uploaded-wrapper {
  position: relative;
  width: 360px;
  height: 252px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  /* margin-top: 16px; */
  border-radius: 4px;
}

.course-file-form-group {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(171, 171, 237, 1)' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='1' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 16px 0;
  align-items: center;
}

.course-file-form-group .input-title {
  font-family: "moderat-semibold";
  font-size: 20px;
  line-height: 150%;
  color: #111111;
  margin-top: 16px;
}

.course-file-form-group .input-sub-title {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #555555;
  width: 60%;
  margin-top: 2px;
}

.course-file-form-group input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.browse-local-button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.browse-local-button-wrapper button {
  min-width: 151px;
  max-width: 180px;
  height: 50px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background: #ede9f7;
  border-radius: 10px;
  font-family: "moderat-medium";
  font-size: 14px;
  color: #3a0ca3;
  border: none;
  padding: 0 10px;
  margin-right: 5px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
