.dashboard-setup-container {
  min-height: 100vh;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 40;
  background-color: white;
  /* padding: 0 16px; */
  font-family: moderat-regular;
  margin-top: 80px;
}

.dashboard-setup-top--left-wrapper {
  display: flex;
}

/*  SETUP LAYOUT SECTION */
/* Dashboard Setup layout Section */
.dashboard-setup-layout-container {
  position: relative;
  max-width: 1000px;
  /* background-color: rebeccapurple; */
  margin: auto;
  padding: 50px 0;
  padding-left: 50px;
  overflow: hidden;
}

.dashboard-setup-top-container {
  display: flex;
  align-items: center;
  margin-left: 25px;
  justify-content: space-between;
}

.push-wrapper-down {
  margin-top: 40px !important;
}

.dashboard-setup-top-container .back-button-wrapper button {
  width: 50px;
  height: 50px;
  background-color: #f8f8fa;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

.dashboard-setup-top-title {
  font-family: "moderat-semibold";
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  color: #1a0404;
  margin-left: 25px;
}

/* Setup Layout main Container */
.dashboard-setup-layout-main-container {
  display: flex;
  margin-top: 40px;
}

/* Dashboard Setup LEFT Section */
.dashboard-setup-layout--left-section {
  width: 400px;
  margin-top: 60px;
  position: relative;
  /* background-color: red; */
}

.log-out-btn-wrapper {
  /* position: absolute;
  bottom: 0;
  left: 0; */
  margin-top: 30px;
}

.log-out-btn-wrapper button {
  width: 200px;
  height: 50px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  line-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ce2e2e;
  color: #ffffff;
}

.form-navigation-link button {
  width: 374px;
  height: 68px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  /* Text styles */
  font-family: "moderat-medium";
  font-size: 17px;
  line-height: 18px;
  color: #a9a9bc;
  background-color: #ffffff;
}

.form-navigation-link button img {
  margin-right: 15px;
}

/* Dashboard Setup RIGHT Section */
.dashboard-setup-layout--right-section {
  margin-left: 45px;
}

.active-form-nav-link {
  background-color: #f8f8fa !important;
  color: #2e2e2e !important;
}

.submit-setup-form-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 25px;
}

.submit-setup-form-button-wrapper button {
  width: 250px;
  height: 50px;
  background-color: #3a0ca3;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  /* Text styles */
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #ffffff;
  margin-left: 10px;
}

/*  */
/*  */
/*  */
.no {
  display: none;
}
