.subscriptions--top-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscriptions--title-wrapper {
  font-weight: 700;
  line-height: 150%;
  color: #2e2e2e;
}

.subscriptions--top-action-btns-container {
  display: flex;
  align-items: center;
  column-gap: 30px;
}

.add-new-plan-btn-wrapper button {
  width: 230px;
  font-size: 14px !important;
}
