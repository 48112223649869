.users--top-container {
  /* margin-top: 58px; */
  display: flex;
  align-items: center;
  column-gap: 44px;
}

/* users--top--box-container */
.users--top--box-container {
  width: 260px;
  height: 160px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 0 20px 30px;
  color: #0d0520;
}
