.app_main_section {
  position: relative;
  min-height: 100vh;
  margin-left: 260px;
  padding: 40px 24px 0;
  margin-top: 80px;
}

/* Media Queries */
@media (min-width: 1900px) {
  .app_main_section {
    margin-left: 320px;
  }
}

@media (min-width: 2100px) {
  .app_main_section {
    margin-left: 380px;
  }
}

@media (max-width: 760px) {
  .app_main_section {
    padding: 40px 20px 0;
  }
}
