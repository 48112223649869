.audit-trail-container {
  padding-bottom: 30px;
  font-family: "moderat-regular";
  position: relative;
}

.audit-trail-container--inner {
  position: relative;
  max-width: 1380px;
  min-height: 100vh;
  display: flex;
  margin: auto;
  overflow: hidden;
}

/* audit-trail-container-main-section */
section.audit-trail-container-main-section {
  position: relative;
  width: 100%;
  background: #f8f8fa;
  margin-left: 260px;
  padding: 0 24px;
  margin-top: 140px;
}

.audit-trail-table-wrapper {
}

/* Media queries */

@media (min-width: 1400px) {
  .audit-trail-container--inner {
    max-width: 1500px;
  }
}

@media (min-width: 1500px) {
  .audit-trail-container--inner {
    max-width: 1700px;
  }
}
